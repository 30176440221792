import React, { useEffect, useState } from "react";
import { Button, Col, Empty, Form, Input, Modal, Row, Spin, message } from "antd";
import logoWithText from "../assets/images/logoWithText.svg";
import smallLogo from "../assets/images/smallLogo.svg";
import celenderIcon from "../assets/images/celenderIcon.svg";
import locationIcon from "../assets/images/locationIcon.svg";
import pptIcon from "../assets/images/pptIcon.svg";
import pdfIcon from "../assets/images/pdfIcon.svg";
import movIcon from "../assets/images/movIcon.svg";
import xlsIcon from "../assets/images/xlsIcon.svg";
import docIcon from "../assets/images/docIcon.svg";
import heart from "../assets/images/heart.svg";
import exportIcon from "../assets/images/exportIcon.svg";

const Home = () => {
  const [submitModal, setSubmitModal] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [file, setFile] = useState("");
  const [data, setData] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);

  const [form] = Form.useForm()
  const content =
    "Welcome to the ILMA Conference 2024, an annual gathering hosted by the Indian Laminate Manufacturers Association (ILMA). This distinguished event stands as a cornerstone in bringing together manufacturers, suppliers, distributors and experts within the vibrant landscape of Indian laminate manufacturing industry.";

  const displayText = showFullText ? content : `${content.slice(0, 300)}`;

  const toggleReadMore = () => {
    setShowFullText(!showFullText);
  };

  const fetchData = async () => {
    try {
      // Replace the URL with your actual API endpoint
      const response = await fetch("https://api.ilma.org.in/presentation");
      const result = await response.json();
      if (parseInt(result.status) === 200) {
        setData(result.data);
      } else if (parseInt(result.status) === 404) {
        setData([]);
      } else {
        message.error(response.message, 5)
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  // ${company}-

  const handleDownload = async (file, form) => {
    const fileName = `${file &&
      file.substring(
        file.lastIndexOf("/") + 1
      )}`
    setDownloadLoader(true)
    try {
      const response = await fetch(file, {
        method: "GET",
        responseType: "blob",
      });
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = fileName;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      setDownloadLoader(false);
      form && form.resetFields();


      setSubmitModal(false);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      setDownloadLoader(false)
      console.error("Error downloading file:", error);
    }
  };

  const handleFileDownload = (file) => {
    setSubmitModal(true);
    setFile(file)
  }

  const onFinish = async (val, file) => {
    console.log({ file });
    const response = await fetch(`https://api.ilma.org.in/contact`, {
      method: 'POST',
      body: JSON.stringify(val),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const result = await response.json();
    if (parseInt(result.status) === 200) {
      console.log({ data: result.data });
      handleDownload(file, form);
      console.log(result);
    } else {
      message.error(result.message, 5)
    }
  }
  return (
    <>
      <div className="bg-[#F6F6F6] min-h-screen flex flex-col justify-between">
        <div>
          <div className="py-4">
            <img
              src={logoWithText}
              alt="logo"
              className="mx-auto md:w-[116px]  w-[48px]"
            />
          </div>
          <div className="w-11/12 md:container mx-auto bg-white rounded-[15px] px-8 py-6 ">
            <div className="flex justify-between md:items-center items-start">
              <div className=" md:flex md:space-x-5 space-y-3 md:space-y-0">
                <div className="border-0 md:border-r pr-5">
                  <p className="text-[11.33px] md:text-[20px] font-semibold">
                    Exhibition Meetup
                  </p>
                  <div className="flex items-center mt-2 space-x-3">
                    <div className="bg-[#E6E6E6] h-[40px] w-[40px] md:h-[50px] md:w-[50px] rounded-full flex items-center justify-center">
                      <img
                        src={smallLogo}
                        alt="logo"
                        className="h-[14px] w-[24px] md:h-[22px] md:w-[40px]"
                      />
                    </div>
                    <div className="w-[168px] md:w-[200px]">
                      <p className="text-[8px] md:text-[13px] text-black">
                        <span className="text-[#A4A4A4] text-[8px] md:text-[15px]">
                          Organized by
                        </span>{" "}
                        Indian Laminate Manufacturers Association
                      </p>
                    </div>
                  </div>
                </div>
                <div className="my-auto space-y-1 md:space-y-3">
                  <div className="flex space-x-1 md:space-x-3">
                    <img src={celenderIcon} alt="icon" />
                    <p className="text-[8px] md:text-[15px] text-black">
                      19th & 20th January, 2024
                    </p>
                  </div>
                  <div className="flex space-x-2 md:space-x-3">
                    <img src={locationIcon} alt="icon" />
                    <p className="text-[8px] md:text-[15px] text-black">
                      Hotel JW Marriott, Goa
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-[34px] h-[34px] md:w-[60px] md:h-[60px] bg-[#3282FB] rounded-lg text-white flex flex-col justify-center items-center">
                <span className="text-[8px] md:text-[14px]">JAN</span>
                <span className="text-[11px] md:text-[19px] font-bold">25</span>
              </div>
            </div>
            <p className="text-[8px] md:text-[14px] text-justify mt-1 text-[#707070]">
              {displayText}
              {content.length > 300 && (
                <span
                  className="cursor-pointer text-[#3282FB] font-semibold"
                  onClick={toggleReadMore}
                >
                  {showFullText ? "Read Less" : " Read More..."}
                </span>
              )}
            </p>
          </div>


          <div className="bg-white rounded-[15px] px-5 custom-box-shadow w-11/12 md:w-[60%] mx-auto mt-5 md:mt-10">
            <p className="text-[10px] md:text-[15px] text-black text-center pt-5">
              Your Presentation Are Ready To Download
            </p>

            {data &&
              data.length > 0 ?
              data.map((d, index) => {
                const getFileIcon = (fileName) => {
                  const fileExtension = fileName.split('.').pop();
                  switch (fileExtension.toLowerCase()) {
                    case "pdf":
                      return pdfIcon;
                    case "ppt":
                    case "pptx":
                      return pptIcon;
                    case "xls":
                    case "xlsx":
                      return xlsIcon;
                    case "doc":
                    case "docx":
                      return docIcon;
                    case "mov":
                      return movIcon;
                    default:
                      return pdfIcon; // You can use a default icon for unknown file types
                  }
                };
                return (


                  <div className={`py-4 flex justify-between ${index === data.length - 1 ? "" : "border-b-2 border-[#E3E3E3]"}`} key={index}>
                    <div className="flex space-x-3 items-center ">
                      <div className="bg-[#D2EFFB] p-2 md:p-4 rounded-full">
                        {d && d.file && (
                          <img src={getFileIcon(d.file)} alt="icon" className="h-[13px] w-[13px] md:h-[24px] md:w-[24px]" />
                        )}

                      </div>
                      <div>
                        <p className="text-[8px] md:text-[16px] text-black">
                          {/*  {d && d.company_name + "-" +
                            d.file &&
                            d.file.substring(
                              d.file.lastIndexOf("/") + 1
                            )} */}
                          {`${d.file &&
                            d.file.substring(
                              d.file.lastIndexOf("/") + 1
                            )}`}
                        </p>
                        <p className="text-[6px] md:text-[11px] text-[#868686]">
                          {d.company_name}
                        </p>
                      </div>
                    </div>
                    <div className="flex space-x-4 items-center">
                      <img
                        className="cursor-pointer h-[13px] w-[13px] md:h-[24px] md:w-[24px]"
                        //   onClick={handleDownload}
                        onClick={() => handleFileDownload(d.file)}
                        src={exportIcon}
                        alt="icon"
                      />
                    </div>

                  </div>);
              })
              :
              <div className="bg-white rounded-[15px] px-5 custom-box-shadow w-11/12 md:w-[60%] mx-auto mt-5 md:mt-10">
                <Empty className="not-found py-10" bordered={false} image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            }


          </div>

        </div>

        <div className="flex my-3 text-black text-[10px]  md:text-[15px] items-center justify-center">
          <div className="flex">
            Crafted with
            <img src={heart} className="mx-1" /> by Vytech Enterprise
          </div>
        </div>
      </div>

      <Modal afterClose={() => { form && form.resetFields(); setFile("") }}
        // title="Subscribe to Exhibition"
        centered
        open={submitModal}
        footer={null}
        onCancel={() => setSubmitModal(false)}
      >
        <p className=" text-[12px] md:text-[18px] font-medium text-center">
          Subscribe to Exhibition
        </p>
        <Spin spinning={downloadLoader}>

          <Form form={form}
            name="basic"
            layout="vertical"

            onFinish={(val) => onFinish(val, file)}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row gutter={[0, 0]}>
              <Col span={24}>
                <Form.Item
                  className="text-left"
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter name"
                    className="md:text-[13px] text-[8px] border border-[#D1D1D1]"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  className="text-left"
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please enter your email",
                    },

                  ]}
                >
                  <Input
                    placeholder="Enter Email"
                    className="md:text-[13px] text-[8px] border border-[#D1D1D1]"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  className="text-left"
                  label="Mobile"
                  name="phone"
                  normalize={(value) =>
                    value.replace(/[^0-9]/g, "").trim()
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile no.",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (value && (value.length !== 10)) {
                          return Promise.reject("Please enter a valid 10-digit mobile number! ");
                        }

                        return Promise.resolve();
                      }
                    })
                  ]}
                >
                  <Input
                    placeholder="Enter mobile no."
                    className="md:text-[13px] text-[8px] border border-[#D1D1D1]"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  className="text-left"
                  label="Company Name"
                  name="company_name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter company name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter company name"
                    className="md:text-[13px] text-[8px] border border-[#D1D1D1]"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Button
                // loading={buttonLoader}
                className="w-full pt-[8px] pb-[10px] h-auto rounded-[5px] flex items-center justify-center text-[15px] md:text-[18px] text-[#fff] border-0 bg-[#3282FB] mt-4"
                htmlType="submit"
              >
                Submit and Download
              </Button>
            </Form.Item>
            <p onClick={() => handleDownload(file)} className="cursor-pointer text-[10px] md:text-[14px] text-[#3282FB] text-center">
              Skip and Continue to Download
            </p>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default Home;
