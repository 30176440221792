import Home from "./component/Home";
import "../src/assets/css/home.css"
import { ConfigProvider } from "antd";

function App() {
  return (
    <ConfigProvider theme={{
      token: {
        fontFamily: 'Inter, sans-serif',
      },
    }}>

      <Home />
    </ConfigProvider >

  );
}

export default App;
